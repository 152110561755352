import { t } from '@purple-dot/translations/i18n';
import { createLearnMoreButton } from './learn-more-modal';

class PurpleDotLearnMore extends HTMLElement {
  connectedCallback() {
    const styleTag = createStyling();
    if (!document.getElementById(styleTag.id)) {
      document.head.appendChild(styleTag);
    }

    const useExtendedDeliveryLanguage =
      this.dataset.useExtendedDeliveryLanguage;

    const brandingTag =
      useExtendedDeliveryLanguage === 'true'
        ? createExtendedDeliveryProtectedBy()
        : createPreorderProtectedBy();

    const existingBrandingTag = this.querySelector('#pd-branding');

    if (!existingBrandingTag) {
      this.append(brandingTag);
    } else {
      this.replaceChild(brandingTag, existingBrandingTag);
    }
  }
}

function createStyling() {
  const styling = document.createElement('style');
  styling.id = 'pd-learn-more-styling';
  styling.innerHTML = `
    .pd-logo:before {
      display: inline-block;
      position: relative;
      content: ' ';
      border-radius: 50%;
      width: 8px;
      height: 8px;
      bottom: 1px;
      background-color: var(--pd-logo-color);
    }
  `;
  return styling;
}

function createPreorderProtectedBy() {
  const branding = document.createElement('p');
  branding.id = 'pd-branding';
  branding.innerHTML = `
    ${t('shopify:shopify-app.waitlist-protected-by')}
    <span class="pd-logo" style="display: inline-block;color: var(--pd-logo-color);">
      Purple Dot
    </span>
  `;

  branding.appendChild(createLearnMoreButton());

  return branding;
}

function createExtendedDeliveryProtectedBy() {
  const branding = document.createElement('p');
  branding.id = 'pd-branding';
  branding.innerHTML = `
    ${t('shopify:shopify-app.extended-delivery-protected-by')}
    <span class="pd-logo" style="display: inline-block;color: var(--pd-logo-color);">
      Purple Dot
    </span>
  `;

  branding.appendChild(
    createLearnMoreButton({
      useExtendedDeliveryLanguage: true,
    })
  );

  return branding;
}

export function defineLearnMoreCustomElement() {
  customElements.define('purple-dot-learn-more', PurpleDotLearnMore);
}
