/* eslint-disable max-classes-per-file */

interface TextElement {
  getText(): string;
  hasText(text: string): boolean;
  matchesText(text: string): boolean;
  setText(text: string): void;
  element(): HTMLElement;
}

function normalizeText(s: string) {
  return s.trim().replace(/\s/g, '').toLowerCase();
}

class InputTextElement implements TextElement {
  el: HTMLInputElement;

  constructor(el: HTMLInputElement) {
    this.el = el;
  }

  getText() {
    return this.el.value;
  }

  hasText(text: string) {
    return normalizeText(this.getText()).includes(normalizeText(text));
  }

  matchesText(text: string) {
    return normalizeText(this.getText()) === normalizeText(text);
  }

  setText(text: string) {
    this.el.value = text;
  }

  element() {
    return this.el;
  }
}

class NodeTextElement implements TextElement {
  el: HTMLElement;

  constructor(el: HTMLElement) {
    this.el = el;
  }

  getText() {
    return this.el.textContent ?? '';
  }

  hasText(text: string) {
    return normalizeText(this.getText()).includes(normalizeText(text));
  }

  matchesText(text: string) {
    return normalizeText(this.getText()) === normalizeText(text);
  }

  setText(text: string) {
    this.el.innerHTML = text;
  }

  element() {
    return this.el;
  }
}

export class ElementText {
  el: TextElement;
  text: string | null;
  mutationObserver: MutationObserver;
  mutationObserverLoopCounter: number;

  constructor(el: HTMLElement) {
    this.el =
      el instanceof HTMLInputElement
        ? new InputTextElement(el)
        : new NodeTextElement(el);
    this.text = null;

    this.mutationObserverLoopCounter = 0;
    this.mutationObserver = new MutationObserver(() => {
      if (this.text && !this.el.hasText(this.text)) {
        this.el.setText(this.text);
      }

      // Try to detect if we are in an infinite mutation observer loop and if so
      // stop to prevent the page from freezing
      if (this.mutationObserverLoopCounter > 1000) {
        this.mutationObserver.disconnect();
      }
      this.mutationObserverLoopCounter += 1;
    });
  }

  setText(text: string, keep = false) {
    this.text = text;
    if (this.text && !this.el.matchesText(this.text)) {
      this.el.setText(this.text);
    }

    if (keep) {
      this.mutationObserverLoopCounter = 0;
      this.mutationObserver.observe(this.el.element(), {
        attributes: true,
        characterData: true,
        childList: true,
        subtree: true,
      });
    } else {
      this.mutationObserver.disconnect();
    }
  }

  hasText(text: string) {
    return this.el.hasText(text);
  }

  disconnect() {
    this.mutationObserver.disconnect();
  }
}
