import { ShopifyThemeListener } from '../shopify-theme/shopify-theme';
import { DebugLogger } from './logger';

export class ShopifyThemeDebugListener
  extends DebugLogger
  implements ShopifyThemeListener
{
  constructor() {
    super('shopify-theme');
  }

  onPageLoaded() {
    this.log('onPageLoaded');
  }

  onProductPageLoaded() {
    this.log('onProductPageLoaded');
  }

  onNewAddToCartForm(...args: any) {
    this.log('onNewAddToCartForm', args);
  }

  onNewCollectionItem(...args: any) {
    this.log('onNewCollectionItem', args);
  }

  onNewCartForm(...args: any) {
    this.log('onNewCartForm', args);
  }

  onNewCheckoutLink(...args: any) {
    this.log('onNewCheckoutLink', args);
  }

  onNewCheckoutOrPaymentElement(...args: any) {
    this.log('onNewCheckoutOrPaymentElement', args);
  }

  onNewCheckoutButton(...args: any) {
    this.log('onNewCheckoutButton', args);
  }
}
