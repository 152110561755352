import * as ParentPageSessionToken from '@purple-dot/browser-sdk/src/parent-page-session-token';
import { createLearnMoreButton } from '@purple-dot/web-components/learn-more/learn-more-modal';
import { CheckoutMethodEnum } from '../purple-dot-config';
import { AddToCartForm } from '../shopify-theme/add-to-cart-form';
import { ShopifyThemeListener } from '../shopify-theme/shopify-theme';
import { fetchCanAddVariantToPreorder } from './backend';

const iframeCommsId = 'd323029f-c21e-458b-9a73-957e7d87db32';
const iframeId = 'add-to-preorder-checkout';

interface IframeMessage {
  meta: {
    messageType: string;
  };
  data: {
    eventName: string;
  };
}

let interval: any;

export class OpenBoxIntegration implements ShopifyThemeListener {
  constructor() {
    const urlParams = new URLSearchParams(window.location.search);

    const openBoxSendTarget = urlParams.get('pd_open_box_send_target');
    const openBoxSendId = urlParams.get('pd_open_box_send');

    if (openBoxSendTarget) {
      window.sessionStorage.setItem('pd-add-to-preorder', openBoxSendTarget);
    }

    if (openBoxSendId) {
      window.sessionStorage.setItem('pd-open-box-send', openBoxSendId);
    }

    if (this.isOpenBoxSessionDataPresent()) {
      window.localStorage.setItem('pd-open-box-mode', 'true');
    }

    if (
      window.localStorage.getItem('pd-open-box-mode') === 'true' &&
      !this.isOpenBoxSessionDataPresent()
    ) {
      window.localStorage.removeItem('pd-open-box-mode');
      this.resetPDSession();
    }

    // Listen for events if an iframe is added to the page
    window.addEventListener('message', onMessage, false);

    window.addEventListener('open-add-to-preorder-iframe', () => {
      openAddToPreorderIframe();
    });
  }

  isOpenBoxSessionDataPresent() {
    const openBoxSendTarget =
      window.sessionStorage.getItem('pd-add-to-preorder');
    const openBoxSendId = window.sessionStorage.getItem('pd-open-box-send');

    return openBoxSendId && openBoxSendTarget;
  }

  resetPDSession() {
    ParentPageSessionToken.setUpNew();
  }

  onNewAddToCartForm(addToCartForm: AddToCartForm) {
    const preorderId = sessionStorage.getItem('pd-add-to-preorder');
    if (!preorderId) {
      return;
    }

    this.addOpenBoxDisplayListenerToForm(addToCartForm);

    addToCartForm.onVariantIdChange((variantId) => {
      if (!variantId) {
        return;
      }
      void this.handleVariantChange(addToCartForm, variantId, preorderId);
    });

    const variantId = addToCartForm.getVariantId();
    if (!variantId) {
      return;
    }

    void this.handleVariantChange(addToCartForm, variantId, preorderId);
  }

  addOpenBoxDisplayListenerToForm(addToCartForm: AddToCartForm) {
    addToCartForm.observe(() => {
      if (
        addToCartForm.getElement().classList.contains('hide-preorder-elements')
      ) {
        this.hideWaitlistInfo(addToCartForm);
      } else {
        this.unhideWaitlistInfo(addToCartForm);
      }
    });
  }

  async handleVariantChange(
    addToCartForm: AddToCartForm,
    variantId: number,
    preorderId: string
  ) {
    this.disableAddToPreorderButton(addToCartForm);

    const result = await fetchCanAddVariantToPreorder({
      preorderId,
      variantId: `${variantId}`,
    });

    /**
     * If the API says the current variant can't be added to the pre-order
     * we stop. Otherwise we try to add the Add to Preorder button to the page
     */
    if (result.canAdd) {
      this.insertAddToPreorderElements(
        addToCartForm,
        variantId,
        result.estimatedShipDateText
      );
    } else {
      this.removeAddToPreorderElements(addToCartForm);
    }

    this.enableAddToPreorderButton(addToCartForm);
  }

  disableAddToPreorderButton(addToCartForm: AddToCartForm) {
    const addToPreorderButton = this.getAddToPreorderButton(addToCartForm);

    if (addToPreorderButton) {
      addToPreorderButton.setAttribute('disabled', 'disabled');
    }
  }

  enableAddToPreorderButton(addToCartForm: AddToCartForm) {
    const addToPreorderButton = this.getAddToPreorderButton(addToCartForm);

    if (addToPreorderButton) {
      addToPreorderButton.removeAttribute('disabled');
    }
  }

  insertAddToPreorderElements(
    addToCartForm: AddToCartForm,
    variantId: number,
    estimatedShipDateText: string
  ) {
    this.removeAddToPreorderInfo(addToCartForm);

    const addToCartButton = addToCartForm.getAddToCartButton()?.getElement();

    if (!addToCartButton) {
      return;
    }

    const addToPreorderButton = this.setupAddToPreorderButton(
      addToCartForm,
      addToCartButton,
      variantId
    );

    this.insertAddToPreorderInfo(addToPreorderButton, estimatedShipDateText);
    addToCartForm.getElement().classList.add('hide-preorder-elements');
  }

  setupAddToPreorderButton(
    addToCartForm: AddToCartForm,
    addToCartButton: HTMLButtonElement,
    variantId: number
  ) {
    let addToPreorderButton = this.getAddToPreorderButton(addToCartForm);

    if (!addToPreorderButton) {
      addToPreorderButton = this.insertAddToPreorderButton(addToCartButton);
    }

    addToPreorderButton.onclick = (ev) => {
      ev.preventDefault();
      ev.stopPropagation();

      openAddToPreorderIframe(variantId);
    };

    return addToPreorderButton;
  }

  getAddToPreorderButton(addToCartForm: AddToCartForm) {
    return addToCartForm.querySelector('#pd-add-to-preorder-button');
  }

  insertAddToPreorderButton(addToCartButton: HTMLButtonElement) {
    // Create a new Add to Preorder Button cloned from the baseButton
    const addToPreorderButton = addToCartButton.cloneNode(
      true
    ) as HTMLButtonElement;
    addToPreorderButton.id = 'pd-add-to-preorder-button';
    (addToPreorderButton as any).innerHTML = 'Add to Pre-order';
    addToPreorderButton.setAttribute('type', 'button');
    addToPreorderButton.removeAttribute('name');

    if (addToPreorderButton.tagName === 'INPUT') {
      addToPreorderButton.setAttribute('value', 'Add to Pre-order');
    }

    addToCartButton?.parentElement?.insertBefore(
      addToPreorderButton,
      addToCartButton
    );

    return addToPreorderButton;
  }

  insertAddToPreorderInfo(
    addToPreorderButton: HTMLElement,
    estimatedShipDateText: string
  ) {
    const rootElem = document.createElement('div');
    rootElem.id = 'pd-add-to-preorder-info';

    addToPreorderButton.insertAdjacentElement('afterend', rootElem);

    // Add Estimated Delivery Date
    const pdDispatchDate = document.createElement('p');
    pdDispatchDate.id = 'pd-add-to-preorder-dispatch-date';
    pdDispatchDate.innerHTML = `${estimatedShipDateText} `;
    pdDispatchDate.appendChild(
      createLearnMoreButton({ isAddToPreorder: true })
    );
    rootElem.appendChild(pdDispatchDate);
  }

  removeAddToPreorderElements(addToCartForm: AddToCartForm) {
    this.removeAddToPreorderButton(addToCartForm);
    this.removeAddToPreorderInfo(addToCartForm);
    addToCartForm.getElement().classList.remove('hide-preorder-elements');
  }

  removeAddToPreorderButton(addToCartForm: AddToCartForm) {
    const addToPreorderButton = addToCartForm.querySelector(
      '#pd-add-to-preorder-button'
    );
    addToPreorderButton?.remove();
  }

  removeAddToPreorderInfo(addToCartForm: AddToCartForm) {
    const addToPreorderInfo = addToCartForm.querySelector(
      '#pd-add-to-preorder-info'
    );
    addToPreorderInfo?.remove();
  }

  unhideWaitlistInfo(addToCartForm: AddToCartForm) {
    const addToCartButton = addToCartForm.getAddToCartButton()?.getElement();

    if (addToCartButton) {
      addToCartButton.classList.remove('pd-hide');
    }
    const waitlistInfo = addToCartForm.querySelector('#pd-waitlist-info');
    waitlistInfo?.classList.remove('pd-hide');
  }

  hideWaitlistInfo(addToCartForm: AddToCartForm) {
    const addToCartButton = addToCartForm.getAddToCartButton()?.getElement();
    if (!addToCartButton) {
      return;
    }

    addToCartButton.classList.add('pd-hide');

    const waitlistInfo =
      document.querySelector<HTMLDivElement>('#pd-waitlist-info');
    if (waitlistInfo) {
      waitlistInfo.classList.add('pd-hide');
    }
  }
}

function onMessage(ev: MessageEvent & { data: IframeMessage }) {
  const { data } = ev;
  if (ev.origin !== window.PurpleDotConfig.hostURL) {
    return;
  }

  if (!data) {
    return;
  }

  if (data.meta && data.meta.messageType === 'handshake-reply') {
    clearInterval(interval);
    return;
  }

  if (data.data && data.data.eventName === 'dismiss-iframe') {
    const dismissIframe = document.querySelector(`#${iframeId}`);
    if (dismissIframe) {
      dismissIframe.parentNode?.removeChild(dismissIframe);
    }
  }
}

function sendHandshake() {
  const iframe = document.querySelector<HTMLIFrameElement>(`#${iframeId}`);
  if (!iframe) {
    return;
  }

  iframe.contentWindow?.postMessage(
    {
      meta: {
        messageType: 'handshake',
      },
      data: {
        iframeId: iframeCommsId,
      },
    },
    window.PurpleDotConfig.hostURL as string
  );
}

function openAddToPreorderIframe(variantId?: string | number) {
  const foundIframe = document.querySelector(`#${iframeId}`);
  if (foundIframe) {
    return;
  }

  const preorderId = window.sessionStorage.getItem('pd-add-to-preorder');

  if (!preorderId) {
    throw new Error(
      'Unable to create add to preorder iframe. No preorderId found in session'
    );
  }

  const iframe = createOverlayIframe({
    id: iframeId,
    src: createAddToPreorderUrl({
      apiKey: window.PurpleDotConfig.apiKey,
      preorderId,
      variantId: variantId?.toString(),
      discountCode: window.PurpleDotConfig?.discountCode ?? undefined,
    }),
  });

  document.body.appendChild(iframe);

  interval = setInterval(sendHandshake, 500);
}

function createAddToPreorderUrl(params: {
  apiKey: string;
  preorderId: string;
  variantId?: string;
  discountCode?: string;
}) {
  const openBoxSendId = window.sessionStorage.getItem('pd-open-box-send');

  const url = new URL(
    `${window.PurpleDotConfig.hostURL}/embedded-checkout/add-to-preorder`
  );

  url.searchParams.append('apiKey', params.apiKey);
  url.searchParams.append('preorderId', params.preorderId);

  if (params.variantId) {
    url.searchParams.append('externalVariantId', params.variantId);
  }

  if (openBoxSendId) {
    url.searchParams.append('openBoxSendId', openBoxSendId);
  }

  if (params.discountCode) {
    url.searchParams.append('discountCode', params.discountCode);
  }

  const parentPageSessionToken = ParentPageSessionToken.getOrCreate();
  url.searchParams.append('parentPageSessionToken', parentPageSessionToken);

  if (
    window.PurpleDotConfig.checkoutMethod === CheckoutMethodEnum.SeparateCart
  ) {
    url.searchParams.append('enableCart', 'true');
  }

  return url.toString();
}

function createOverlayIframe({ id, src }: { id: string; src: string }) {
  const iframe = document.createElement('iframe');
  iframe.id = id;
  iframe.src = src;

  iframe.style.position = 'fixed';
  iframe.style.top = '0';
  iframe.style.left = '0';
  iframe.style.bottom = '0';
  iframe.style.right = '0';
  iframe.style.width = '100%';
  iframe.style.height = '100%';
  iframe.style.zIndex = '2147483647';

  return iframe;
}
