export function extractHandle(url: string) {
  const parsedUrl = new URL(url);
  const match = parsedUrl.pathname.match(/\/products\/(.*)$/);

  if (match) {
    return match[1];
  }

  return undefined;
}

export function addUrlParam(base: string, name: string, variantId: string) {
  let uriBridge = '?';
  if (base.includes('?')) {
    uriBridge = '&';
  }
  const variantHref = `${base}${uriBridge}${name}=${variantId}`;
  return variantHref;
}

export function isProductPage() {
  return window.location.pathname.includes('/products/');
}
