/**
 * ShopifyInventoryTracker
 *
 * Listens for Shopify Theme events and then
 * tracks the quantity available of products and variants.
 *
 * This allows merchants to receive alerts when products
 * are about to go out of stock.
 */

import { ShopifyApi, ShopifyProduct } from '../shopify-api';
import { AddToCartForm } from '../shopify-theme/add-to-cart-form';
import { parseRequestBody } from '../shopify-theme/interceptors/interceptor';
import { ShopifyThemeListener } from '../shopify-theme/shopify-theme';
import { fetchVariantsPreorderState } from './backend';

class ShopifyInventoryTracker implements ShopifyThemeListener {
  constructor(private shopifyApi: ShopifyApi) {}

  async onNewAddToCartForm(addToCartForm: AddToCartForm) {
    let product = await this._getProductForVariant(
      addToCartForm.getVariantId(),
      addToCartForm.getHandle()
    );

    if (product) {
      this._trackProductViewed(product);

      const variantId = addToCartForm.getVariantId();
      if (variantId) {
        await this._trackVariantSelected(variantId);
      }
    }

    addToCartForm.onVariantIdChange(async (variantId) => {
      if (!variantId) {
        return;
      }

      if (!product) {
        product = await this._getProductForVariant(variantId);
        if (product) {
          this._trackProductViewed(product);
        }
      }

      await this._trackVariantSelected(variantId);
    });
  }

  onAddToCart(
    [input, init]: [input: string | URL, init?: RequestInit],
    onComplete: Promise<unknown>
  ): Promise<[input: string | URL, init?: RequestInit]> {
    void onComplete.then(() => {
      if (init) {
        const body = parseRequestBody(init);

        if ('items' in body) {
          for (const item of body.items) {
            window.PurpleDot.track.raw('integration.added_to_cart', {
              id: item.id,
              release_id: item.properties?.__releaseId,
            });
          }
        } else if (
          body instanceof URLSearchParams ||
          body instanceof FormData
        ) {
          const idField = body.get('id') as string;

          if (idField) {
            window.PurpleDot.track.raw('integration.added_to_cart', {
              id: idField,
              release_id: body.get('properties[__releaseId]')?.toString(),
            });
          }
        } else if ('id' in body) {
          window.PurpleDot.track.raw('integration.added_to_cart', {
            id: body.id,
            release_id: body.properties?.__releaseId,
          });
        }
      }
    });

    return Promise.resolve([input, init]);
  }

  _trackProductViewed(product: ShopifyProduct) {
    window.PurpleDot.track.productViewed({
      productId: `${product.id}`,
      available: product.available,
      skus: product.variants.map((variant) => ({
        skuId: `${variant.id}`,
        available: variant.available,
        stockQuantity: variant.inventory_quantity,
      })),
    });
  }

  async _trackVariantSelected(variantId: number) {
    const state = await fetchVariantsPreorderState(variantId);
    if (state?.product.handle) {
      const shopifyProduct = await this.shopifyApi.fetchProduct(
        state.product.handle
      );
      const shopifyVariant = shopifyProduct?.variants.find(
        (v) => v.id === variantId
      );
      window.PurpleDot.track.skuSelected({
        productId: shopifyProduct ? `${shopifyProduct.id}` : null,
        skuId: `${variantId}`,
        available: shopifyVariant?.available,
        stockQuantity: shopifyVariant?.inventory_quantity,
        state,
        releaseId: state?.waitlist?.id,
      });
    }
  }

  async _getProductForVariant(
    variantId?: number | null,
    handle?: string | null
  ) {
    let productHandle = handle;

    if (!productHandle && variantId) {
      const state = await fetchVariantsPreorderState(variantId);
      productHandle = state?.product.handle;
    }

    if (productHandle) {
      return await this.shopifyApi.fetchProduct(productHandle);
    }

    return null;
  }
}

export { ShopifyInventoryTracker };
