/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */

import getDeviceId from '@purple-dot/browser-sdk/src/device-id';
import {
  NewEndpointPreorderState,
  fetchVariantsPreorderState,
} from './purple-dot-integration/backend';
import {
  ShopifyApi,
  ShopifyCartLineItem,
  ShopifyLineItemProperties,
} from './shopify-api';
import {
  AddToCartJSON,
  CartItem,
  makeRequestBody,
  parseRequestBody,
} from './shopify-theme/interceptors/interceptor';
import { ShopifyThemeListener } from './shopify-theme/shopify-theme';
import { WaitlistAvailability } from './waitlist-availability';

function getCartLink() {
  const prefix = window.Shopify?.routes?.root || '/';
  return `${prefix}cart`;
}

export class CartTools implements ShopifyThemeListener {
  constructor(
    private shopifyApi: ShopifyApi,
    private waitlistAvailability: Pick<WaitlistAvailability, 'waitlistsEnabled'>
  ) {}

  /*
    onCheckoutNavigation
    handles navigation from a tags that are clicked going to the checkout
  */
  public async onCheckoutNavigation(url: URL): Promise<URL> {
    const disallowCheckout = await this.disallowCheckout();

    if (disallowCheckout) {
      const cartLink = getCartLink();
      return new URL(cartLink, url);
    }

    return url;
  }

  /*
    onCheckoutSubmit
    intercepts and handles if the form with action /checkout is submitted
  */
  public async onCheckoutSubmit([input, init]: [
    input: string | URL,
    init?: RequestInit,
  ]): Promise<[input: string | URL, init?: RequestInit]> {
    const disallowCheckout = await this.disallowCheckout();

    if (init && disallowCheckout) {
      window.location.href = getCartLink();
      return ['NO_REDIRECT'];
    }

    return [input, init];
  }

  public async onAddToCart([input, init]: [
    input: string | URL,
    init?: RequestInit,
  ]): Promise<[input: string | URL, init?: RequestInit]> {
    if (!init || !init?.body) {
      return [input, init];
    }

    if (init.method?.toUpperCase() === 'POST' || init.body) {
      let newBody;

      try {
        newBody = parseRequestBody(init);
      } catch {
        // Unsupported body type, oh dear.
        return [input, init];
      }

      await this.fixAddToCart(newBody);

      return [input, { ...init, body: makeRequestBody(init, newBody) }];
    }

    if (init.method?.toUpperCase() === 'GET') {
      const newURL = new URL(input);
      await this.fixAddToCart(newURL.searchParams);
      return [newURL, init];
    }

    return [input, init];
  }

  public async onCartSubmit([input, init]: [
    input: string | URL,
    init?: RequestInit,
  ]): Promise<[input: string | URL, init?: RequestInit]> {
    const disallowCheckout = await this.disallowCheckout();
    if (disallowCheckout) {
      const newInput = new URL(input, window.location.href);
      for (const [key] of Array.from(newInput.searchParams)) {
        if (key.startsWith('checkout')) {
          newInput.searchParams.delete(key);
        }
      }

      if (init) {
        const body = parseRequestBody(init);
        if (body) {
          let mutated = false;

          if (body instanceof FormData || body instanceof URLSearchParams) {
            for (const key of Array.from(body.keys())) {
              if (key.startsWith('checkout')) {
                body.delete(key);
                mutated = true;
              }
            }
          } else {
            for (const key of Array.from(Object.keys(body))) {
              if (key.startsWith('checkout')) {
                delete body[key];
                mutated = true;
              }
            }
          }

          if (mutated) {
            return [newInput, { ...init, body: makeRequestBody(init, body) }];
          }
        }
      }

      return [newInput, init];
    }

    return [input, init];
  }

  public async onListenerAdded() {
    await this.getFixedCartItems();
  }

  public async cartHasPreorderItems() {
    const items = await this.getFixedCartItems();
    return items.some((item) => item.properties?.__releaseId);
  }

  private async disallowCheckout() {
    return await this.cartHasPreorderItems();
  }

  private async getFixedCartItems() {
    const cart = await this.shopifyApi.fetchCart();
    const cartItems: Record<string, ShopifyCartLineItem> = Object.fromEntries(
      cart.items.map((item) => [item.key, item])
    );

    let latestCartItems = cart.items;

    for (const { key } of cart.items) {
      const item = cartItems[key];
      const variantId = item.variant_id;

      const properties = await this.updateProperties(
        variantId,
        item.properties ?? {}
      );

      const changed =
        item.properties?.__releaseId !== properties.__releaseId ||
        item.properties?.['Purple Dot Pre-order'] !==
          properties['Purple Dot Pre-order'] ||
        item.properties?.['Purple Dot Payment Plan'] !==
          properties['Purple Dot Payment Plan'];

      if (changed) {
        const cartChange = await this.shopifyApi.changeCartItem(item.key, {
          properties,
          quantity: item.quantity,
          sellingPlanId: item.selling_plan_allocation?.selling_plan?.id,
        });

        // changeCartItem may update other keys too, so we keep track of the latest version of each key
        // to avoid writing stale data to that line item.
        latestCartItems = cartChange.items;
        for (const newItem of cartChange.items) {
          cartItems[newItem.key] = newItem;
        }
      }
    }

    return latestCartItems;
  }

  private async newPdProperties(variantId: number) {
    const response = await fetchVariantsPreorderState(variantId);
    const waitlistsEnabled = await this.waitlistAvailability.waitlistsEnabled();

    if (
      response?.waitlist &&
      (response.state === NewEndpointPreorderState.OnPreorder ||
        response.state === NewEndpointPreorderState.SoldOut) &&
      waitlistsEnabled
    ) {
      const newProps = {
        __pdDebug: getDeviceId().deviceId,
        __releaseId: response.waitlist.id,
        'Purple Dot Pre-order': response.waitlist.display_dispatch_date ?? '',
      };

      if (response.waitlist.payment_plan_descriptions?.short) {
        newProps['Purple Dot Payment Plan'] =
          response.waitlist.payment_plan_descriptions.short;
      }

      return newProps;
    }
    return null;
  }

  private async updateProperties(
    variantId: number,
    properties: ShopifyLineItemProperties
  ): Promise<ShopifyLineItemProperties> {
    const newPdProperties = await this.newPdProperties(variantId);

    const newProperties = { ...properties, ...newPdProperties };

    if (newPdProperties == null) {
      deleteKey(newProperties, '__releaseId');
      deleteKey(newProperties, 'Purple Dot Pre-order');
      deleteKey(newProperties, 'Purple Dot Payment Plan');
    }

    return newProperties;
  }

  private extractAddToCartProperties(
    data: FormData | URLSearchParams | CartItem
  ) {
    if ('id' in data) {
      return data.properties ?? {};
    }

    const properties: { [key: string]: string } = {};

    data.forEach((value, key) => {
      const keyMatch = key.match(/.*\[(.*)\]/);

      if (keyMatch && keyMatch.length > 0) {
        const propKey = keyMatch[1];
        properties[propKey] = value as string;
      }
    });

    return properties;
  }

  private async fixAddToCart(data: FormData | URLSearchParams | AddToCartJSON) {
    if (await this.fixObjectCartAdd(data)) {
      return;
    }

    if (await this.fixFormNestedCartAdd(data)) {
      return;
    }

    await this.fixFormSingleCartAdd(data);
  }

  private async fixObjectCartAdd(
    data: FormData | URLSearchParams | AddToCartJSON
  ) {
    // Fix the documented /cart/add.js API
    // https://shopify.dev/api/ajax/reference/cart

    if ('items' in data) {
      for (const item of data.items) {
        const newProps = await this.updateProperties(
          item.id,
          item.properties ?? {}
        );

        item.properties = newProps;
      }

      return true;
    }

    return false;
  }

  private async fixFormNestedCartAdd(
    data: FormData | URLSearchParams | AddToCartJSON
  ) {
    // Fix the square[bracket] encoded multi-item /cart/add API
    // This seems to work because Shopify accept PHP/Rails style form data.

    if (!(data instanceof FormData || data instanceof URLSearchParams)) {
      return false;
    }

    const dataKeys = 'keys' in data ? Array.from(data.keys()) : [];
    let changed = false;

    for (const key of dataKeys) {
      const keyMatch = key.match(/items\[(.*)\]\[id\]/);

      if (keyMatch && keyMatch.length > 0) {
        changed = true;

        const itemIndex = Number.parseInt(keyMatch[1], 10);
        const variantId = Number.parseInt(data.get(key) as string, 10);

        const properties = {};

        for (const key of dataKeys) {
          const propKeyMatch = key.match(
            `items\\[${itemIndex}\\]\\[properties\\]\\[(.*)\\]`
          );

          if (propKeyMatch && propKeyMatch.length > 0) {
            const propKey = propKeyMatch[1];
            properties[propKey] = data.get(key);
          }
        }

        const newProperties = await this.updateProperties(
          variantId,
          properties
        );

        mergeNewProperties(data, newProperties, {
          releaseId: `items[${itemIndex}][properties][__releaseId]`,
          releaseDate: `items[${itemIndex}][properties][Purple Dot Pre-order]`,
          preorderPaymentPlanProp: `items[${itemIndex}][properties][Purple Dot Payment Plan]`,
        });
      }
    }

    return changed;
  }

  private async fixFormSingleCartAdd(
    data: FormData | URLSearchParams | AddToCartJSON
  ) {
    // Fix the single item form style /cart/add API

    if ('items' in data) {
      return;
    }

    let variantId;

    if ('id' in data && data.id) {
      variantId = data.id;
    } else if ('get' in data) {
      variantId = data.get('id');
    }

    if (!variantId) {
      return;
    }

    const properties = this.extractAddToCartProperties(data);
    const newProperties = await this.updateProperties(variantId, properties);

    if (newProperties === null) {
      return;
    }

    if ('id' in data) {
      data.properties = newProperties;

      if (data.properties.__releaseId && 'checkout' in data) {
        deleteKey(data, 'checkout');
      }
    } else {
      mergeNewProperties(data, newProperties, {
        releaseId: 'properties[__releaseId]',
        releaseDate: 'properties[Purple Dot Pre-order]',
        preorderPaymentPlanProp: 'properties[Purple Dot Payment Plan]',
      });
    }
  }
}

function deleteKey(thing: any, key: string) {
  delete thing[key];
}

function mergeNewProperties(
  data: FormData | URLSearchParams,
  newProperties: Record<string, string>,
  propNames: {
    releaseId: string;
    releaseDate: string;
    preorderPaymentPlanProp: string;
  }
) {
  const {
    releaseId: releaseIdProp,
    releaseDate: releaseDateProp,
    preorderPaymentPlanProp,
  } = propNames;
  if (newProperties.__releaseId) {
    data.set(releaseIdProp, newProperties.__releaseId);
  } else {
    data.delete(releaseIdProp);
  }

  if (newProperties['Purple Dot Pre-order']) {
    data.set(releaseDateProp, newProperties['Purple Dot Pre-order']);
  } else {
    data.delete(releaseDateProp);
  }

  if (newProperties['Purple Dot Payment Plan']) {
    data.set(preorderPaymentPlanProp, newProperties['Purple Dot Payment Plan']);
  } else {
    data.delete(preorderPaymentPlanProp);
  }

  if (data.has(releaseIdProp)) {
    data.delete('checkout');
  }
}
