export async function fetchProductsPreorderState(
  handle: string
): Promise<ProductPreorderState | null> {
  const url = new URL(
    `${window.PurpleDotConfig.hostURL}/api/v1/products/preorder-state`
  );

  identifyShop(url);

  url.searchParams.set('handle', handle);
  const resp = await fetch(url.toString());

  if (resp.ok) {
    const body = await resp.json();
    return body.data;
  }

  return null;
}

export interface ProductPreorderState {
  state: NewEndpointPreorderState;
  waitlist: {
    id: string;
    is_scheduled: boolean;
    launch_date: string | null;
    selling_plan_id: string | null;
    display_dispatch_date: string;
    payment_plan_descriptions: {
      long: string;
      short: string;
    } | null;
    use_extended_delivery_language: boolean;
    units_left: number;
  } | null;
}

export enum NewEndpointPreorderState {
  NoOpenWaitlists = 'NO_OPEN_WAITLISTS',
  AvailableInStock = 'AVAILABLE_IN_STOCK',
  OnPreorder = 'ON_PREORDER',
  SoldOut = 'SOLD_OUT',
}

export interface VariantPreorderState {
  state: NewEndpointPreorderState;
  waitlist: {
    id: string;
    is_scheduled: boolean;
    launch_date: string | null;
    selling_plan_id: string | null;
    display_dispatch_date: string;
    payment_plan_descriptions: {
      long: string;
      short: string;
    } | null;
    use_extended_delivery_language: boolean;
    units_left: number;
  } | null;
  product: {
    id: string;
    handle: string;
  };
}

export async function fetchVariantsPreorderState(
  variantId: number
): Promise<VariantPreorderState | null> {
  const url = new URL(
    `${window.PurpleDotConfig.hostURL}/api/v1/variants/preorder-state`
  );

  identifyShop(url);

  url.searchParams.set('variant_id', variantId.toString());
  const resp = await fetch(url.toString());

  if (resp.ok) {
    const body = await resp.json();
    return body.data;
  }

  return null;
}

function identifyShop(url: URL) {
  if (window.PurpleDot?.getApiKey()) {
    url.searchParams.set('api_key', window.PurpleDot.getApiKey());
  } else {
    if (window.Shopify.shop) {
      url.searchParams.set('shop', window.Shopify.shop);
    }
  }
}

export async function fetchCanAddVariantToPreorder({
  preorderId,
  variantId,
}: {
  preorderId: string;
  variantId: string;
}) {
  const url = new URL(
    `${window.PurpleDotConfig.hostURL}/api/v1/can-add-to-preorder`
  );

  identifyShop(url);

  url.searchParams.set('preorderId', preorderId);
  url.searchParams.set('externalVariantId', variantId);
  const resp = await fetch(url.toString());

  if (resp.ok) {
    const body = await resp.json();
    return body.data;
  }

  return undefined;
}
