export function formDataFromSubmit(event: SubmitEvent) {
  const form = event.target as HTMLFormElement;
  const formData = new FormData(form);
  const submitter = event.submitter as HTMLInputElement;

  if (submitter?.name) {
    formData.set(submitter.name, submitter.value);
  }

  return formData;
}
