type FetchParams = [input: string | URL, init?: RequestInit];

export function submitFormData([url, init]: FetchParams) {
  const form = document.createElement('form');
  form.classList.add('pd-submit-intercept');
  form.setAttribute('method', init?.method ?? 'post');
  form.setAttribute('action', url.toString());

  if (init?.body) {
    const formData = init?.body as FormData;

    formData.forEach((value, key) => {
      const input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', key);
      input.setAttribute('value', value as string);

      form.appendChild(input);
    });
  }

  document.body.appendChild(form);
  form.submit();
  form.remove();
}
