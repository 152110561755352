import { createURL } from '@purple-dot/libraries/src/url';
import { t } from '@purple-dot/translations/i18n';
import { autoDetectLocale } from '@purple-dot/translations/locale';

interface StoreIdentifier {
  storeId?: string;
  apiKey?: string;
  shop?: string;
}

export function createLearnMoreButton({
  HOST = 'https://www.purpledotprice.com',
  hideBackToProduct = false,
  isAddToPreorder = false,
  useExtendedDeliveryLanguage = false,
  storeIdentifier = getStoreIdentifier(),
} = {}) {
  const learnMoreBtn = document.createElement('span');
  learnMoreBtn.innerHTML = t('shopify:shopify-app.learn-more');

  learnMoreBtn.style.textDecoration = 'underline';
  learnMoreBtn.style.cursor = 'pointer';
  learnMoreBtn.style.background = 'none';
  learnMoreBtn.style.border = 'none';

  learnMoreBtn.addEventListener('click', () => {
    const iframeCommsId = 'd323029f-c21e-458b-9a73-957e7d87db32';
    const id = 'pd-learn-more-iframe';

    const iframe = createOverlayIframe({
      id,
      src: learnMoreIframeSrc({
        iframeCommsId,
        isAddToPreorder,
        storeIdentifier,
        useExtendedDeliveryLanguage,
      }),
    });
    document.body.appendChild(iframe);

    const interval = setInterval(sendHandshake, 500);

    interface IframeMessage {
      meta: {
        messageType: string;
      };
      data: {
        eventName: string;
      };
    }

    function onMessage(ev: { data: IframeMessage }) {
      const { data } = ev;

      if (!data) {
        return;
      }

      if (data.meta && data.meta.messageType === 'handshake-reply') {
        clearInterval(interval);
        return;
      }

      if (data.data && data.data.eventName === 'dismiss-iframe') {
        const dismissIframe = document.querySelector(`#${id}`);
        if (dismissIframe) {
          dismissIframe.parentNode?.removeChild(dismissIframe);
        }
      }
    }

    function sendHandshake() {
      if (!iframe) {
        return;
      }

      iframe.contentWindow?.postMessage(
        {
          meta: {
            messageType: 'handshake',
          },
          data: {
            iframeId: iframeCommsId,
          },
        },
        HOST
      );
    }

    window.addEventListener('message', onMessage, false);
  });

  function createOverlayIframe({ id, src }: { id: string; src: string }) {
    const iframe = document.createElement('iframe');
    iframe.id = id;
    iframe.src = src;

    iframe.style.position = 'fixed';
    iframe.style.top = '0';
    iframe.style.left = '0';
    iframe.style.bottom = '0';
    iframe.style.right = '0';
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    iframe.style.zIndex = '2147483647';

    return iframe;
  }

  function learnMoreIframeSrc({
    iframeCommsId,
    isAddToPreorder,
    storeIdentifier,
    useExtendedDeliveryLanguage,
  }: {
    iframeCommsId: string;
    isAddToPreorder: boolean;
    storeIdentifier: {
      storeId?: string;
      apiKey?: string;
      shop?: string;
    };
    useExtendedDeliveryLanguage: boolean;
  }) {
    return createURL({
      host: HOST,
      path: '/embedded-checkout/pre-order-value-prop',
      queryParams: {
        hideBackToProduct,
        locale: autoDetectLocale(),
        isAddToPreorder,
        useExtendedDeliveryLanguage,
        ...storeIdentifier,
      },
      hash: `#!iframeId=${iframeCommsId}`,
    });
  }

  return learnMoreBtn;
}

function getStoreIdentifier(): StoreIdentifier {
  if (window.PurpleDotConfig?.apiKey) {
    return { apiKey: window.PurpleDotConfig?.apiKey };
  }

  if (window.Shopify?.shop) {
    return { shop: window.Shopify?.shop };
  }

  return {};
}
