import setUpInternalTracking from '@purple-dot/browser-sdk/src/internal-events';
import untypedPublicEvents from '@purple-dot/browser-sdk/src/public-events';
import PurpleDotSDK from '@purple-dot/browser-sdk/src/sdk';
import trackEvent from '@purple-dot/browser-sdk/src/track-event';
import { mapObj } from '@purple-dot/libraries/src/language-helpers/objects';
import { tapErr } from '@purple-dot/libraries/src/language-helpers/promises';
import {
  EventForwardingConfig,
  setupEventForwarding,
} from './event-forwarding';
import { PrefillShippingAddress } from './purple-dot-config';
import { ShopifyCart } from './shopify-api';

export function setupSDK({
  hostUrl,
  apiKey,
  shopifyCart,
  shopifyCartString,
  shopifyCartSignature,
  discountCode,
  enableCart,
  currency,
  country,
  forwardingConfig,
  prefillEmail,
  prefillShippingAddress,
  locale,
}: {
  hostUrl?: string;
  apiKey?: string;
  shopifyCart?: ShopifyCart;
  shopifyCartString?: string;
  shopifyCartSignature?: string;
  discountCode?: string | null;
  enableCart?: boolean;
  currency?: string;
  country?: string;
  forwardingConfig?: EventForwardingConfig;
  prefillEmail?: string | null;
  prefillShippingAddress?: PrefillShippingAddress | null;
  locale?: string | null;
} = {}) {
  function trackAndThrow(err: any) {
    if (!err.statusCode || err.statusCode < 500) {
      trackEvent('sdk_error', { error: err.toString() });
    }

    throw err;
  }

  function wrapWithErrTrack(obj: any): any {
    return mapObj((name: string, attr: string) => {
      if (typeof attr === 'function') {
        const wrappedFunction = tapErr(trackAndThrow, attr);
        return [name, wrappedFunction];
      }

      if (typeof attr === 'object') {
        return [name, wrapWithErrTrack(attr)];
      }

      return [name, attr];
    }, obj);
  }

  try {
    const publicEvents = untypedPublicEvents as { ready: () => void };
    const purpleDot = wrapWithErrTrack(
      PurpleDotSDK({ publicEvents, hostURL: hostUrl })
    );
    purpleDot.init({
      apiKey,
      shopifyCart,
      shopifyCartString,
      shopifyCartSignature,
      discountCode,
      enableCart,
      currency,
      country,
      locale,
    });

    if (prefillEmail || prefillShippingAddress) {
      purpleDot.setCustomerData(() => {
        return {
          email: prefillEmail,
          address: prefillShippingAddress
            ? {
                firstName: prefillShippingAddress.first_name,
                lastName: prefillShippingAddress.last_name,
                line1: prefillShippingAddress.address1,
                line2: prefillShippingAddress.address2,
                city: prefillShippingAddress.city,
                province: prefillShippingAddress.province_code,
                country: prefillShippingAddress.country_code,
                postalCode: prefillShippingAddress.zip,
                phoneNumber: prefillShippingAddress.phone,
              }
            : null,
        };
      });
    }
    window.PurpleDot = purpleDot;

    setUpInternalTracking(purpleDot);

    setupEventForwarding(forwardingConfig ?? {}, purpleDot);

    publicEvents.ready();
  } catch (err) {
    trackAndThrow(err);
  }
}
