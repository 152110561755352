import { GetWaitlistsResponseItem } from '@purple-dot/main/src/presentation-layer/custom-server/api/public/views/get-waitlists-response';
import { ShopifyProduct } from '../shopify-api';
import { AddToCartForm } from '../shopify-theme/add-to-cart-form';
import { OverrideStyle } from '../shopify-theme/override-style';
import { WaitlistAvailability } from '../waitlist-availability';
import { NewEndpointPreorderState } from './backend';
import { PreorderStateListener } from './preorder-add-to-cart-form';

const DISPLAY_NONE = 'display: none !important';

class SoldOutElements implements PreorderStateListener {
  atcForm!: AddToCartForm;
  selectors: string[];
  private waitlistAvailability: WaitlistAvailability;
  private styleOverride: OverrideStyle;

  constructor({
    atcForm,
    waitlistAvailability,
  }: {
    atcForm: AddToCartForm;
    waitlistAvailability: WaitlistAvailability;
  }) {
    this.atcForm = atcForm;
    this.waitlistAvailability = waitlistAvailability;
    this.selectors = [
      '.klaviyo-bis-trigger',
      '[data-shopify="payment-button"]',
    ];

    this.styleOverride = new OverrideStyle(this.atcForm.el);
  }

  onMount() {
    return;
  }

  onPreorder() {
    this.styleOverride.setStyles({
      '.klaviyo-bis-trigger': [DISPLAY_NONE],
      '[data-shopify="payment-button"]': [DISPLAY_NONE],
    });
    this.styleOverride.enable();
  }

  inStock() {
    this.styleOverride.setStyles({
      '.klaviyo-bis-trigger': [DISPLAY_NONE],
    });
    this.styleOverride.enable();
  }

  soldOut() {
    this.styleOverride.setStyles({
      '[data-shopify="payment-button"]': [DISPLAY_NONE],
    });
    this.styleOverride.enable();
  }

  unknownState() {
    this.styleOverride.setStyles({});
    this.styleOverride.disable();
  }

  noVariantSelected(
    _product: ShopifyProduct,
    _waitlist: GetWaitlistsResponseItem | null,
    _addToCartForm: AddToCartForm,
    state: NewEndpointPreorderState | undefined
  ) {
    if (state === NewEndpointPreorderState.OnPreorder) {
      this.onPreorder();
    }
  }
}

export { SoldOutElements };
