import { debug } from 'debug';
import { DebugLogger } from './logger';
import { PreorderStateDebugListener } from './pre-order-state';
import { ShopifyThemeDebugListener } from './shopify-theme';

export function configureDebug() {
  const query = new URLSearchParams(window.location.search);
  const pdDebug = query.get('PD_DEBUG');

  if (pdDebug === 'true') {
    debug.enable('pd:*');
  } else if (pdDebug) {
    debug.enable(pdDebug);
  }
}

export { ShopifyThemeDebugListener, PreorderStateDebugListener, DebugLogger };
