import EventsIntegration from '@purple-dot/browser-sdk/src/events-integrations';

export interface EventForwardingConfig {
  facebookPixel?: boolean;
  googleAdsTag?: {
    sendTo: string;
  };
  googleTag?: {
    sendTo: string;
  };
  gtmDataLayer?: boolean;
  googleAnalytics?: boolean;
  klaviyo?: boolean;
  tikTokPixel?: boolean;
  yotpo?: boolean;
  northbeamPixel?: boolean;
}

export function setupEventForwarding(
  config: EventForwardingConfig,
  sdk: { on: (...args: any[]) => void }
) {
  const eventsIntegration = EventsIntegration(sdk.on);

  if (config.facebookPixel !== false) {
    eventsIntegration.connectFacebookPixel();
  }

  if (config.googleAnalytics !== false) {
    eventsIntegration.connectGoogleAnalytics();
  }

  if (config.gtmDataLayer) {
    eventsIntegration.connectGTMDataLayer();
  }

  if (config.googleAdsTag) {
    eventsIntegration.connectGoogleAdsTag(config.googleAdsTag);
  }

  if (config.googleTag) {
    eventsIntegration.enableGoogleTags(config.googleTag);
  }

  if (config.klaviyo) {
    // biome-ignore lint/suspicious/noConsole: log
    console.warn(
      '`window.PurpleDotConfig.klaviyo is deprecated. Please configure the Klaviyo integration from the Integration page in the Purple Dot merchant portal'
    );
  }

  if (config.tikTokPixel) {
    eventsIntegration.connectTikTokPixel();
  }

  if (config.yotpo) {
    eventsIntegration.connectYotpo();
  }

  if (config.northbeamPixel) {
    eventsIntegration.connectNorthbeamPixel();
  }
}
