import { v4 as uuid } from 'uuid';
import { ShopifyProduct } from '../shopify-api';

class ButtonIframe {
  private instanceId: string;

  constructor(
    private hostUrl: string,
    private cartElem: HTMLElement
  ) {
    this.instanceId = uuid();
  }

  mount(product: ShopifyProduct, variantId: number) {
    const el = this.getContainerEl();
    if (!el) {
      const div = document.createElement('div');
      div.setAttribute('data-purple-dot-placement-type', 'button');
      div.setAttribute('data-purple-dot-instance-id', this.instanceId);
      div.style.display = 'none';
      this.cartElem.appendChild(div);
      window.PurpleDot.load({
        placementType: 'button',
        instanceId: this.instanceId,
        productId: product.id,
        skuId: variantId,
      });
    } else {
      window.PurpleDot.update({
        placementType: 'button',
        instanceId: this.instanceId,
        productId: product.id,
        skuId: variantId,
      });
    }
  }

  onButtonClicked(data: { [k: string]: number | string }) {
    const iframe = this.getIframeEl();

    if (!iframe) {
      return;
    }

    iframe.contentWindow?.postMessage(
      {
        meta: { type: 'CLICK' },
        data,
      },
      this.hostUrl
    );
  }

  onNotifyMeClicked() {
    const iframe = this.getIframeEl();

    if (!iframe) {
      return;
    }

    iframe.contentWindow?.postMessage(
      {
        meta: { type: 'NOTIFY_ME_CLICK' },
      },
      this.hostUrl
    );
  }

  onLearnMoreClicked() {
    const iframe = this.getIframeEl();

    if (!iframe) {
      return;
    }

    iframe.contentWindow?.postMessage(
      {
        meta: { type: 'LEARN_MORE_CLICK' },
      },
      this.hostUrl
    );
  }

  private getContainerEl(): HTMLElement | null {
    return this.cartElem.querySelector(
      `[data-purple-dot-placement-type="button"][data-purple-dot-instance-id="${this.instanceId}"]`
    );
  }

  private getIframeEl(): HTMLIFrameElement | null {
    return this.cartElem.querySelector(
      `[data-purple-dot-placement-type="button"][data-purple-dot-instance-id="${this.instanceId}"] > iframe`
    );
  }
}

export { ButtonIframe };
