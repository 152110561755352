/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-rest-params */
/* eslint-disable class-methods-use-this */
import { captureError } from '../../debug/capture-error';
import { formDataFromSubmit } from '../../form-data-from-submit';
import { submitFormData } from '../../submit-form-data';
import { Interceptor, shopifyUrlStartsWith } from './interceptor';

type FetchParams = [input: string | URL, init?: RequestInit];

export class CheckoutFormInterceptor extends Interceptor<FetchParams> {
  constructor() {
    super();
    this.addSubmitListener();
  }

  private addSubmitListener() {
    const submitListener = (event: SubmitEvent) => {
      if (
        event.target &&
        !event.defaultPrevented &&
        event.target instanceof HTMLElement &&
        !event.target.classList.contains('pd-submit-intercept')
      ) {
        const form = event.target as HTMLFormElement;
        const formData = formDataFromSubmit(event);
        const request: FetchParams = [
          new URL(form.action, window.location.href),
          { method: form.method ?? 'get', body: formData },
        ];

        if (shouldIntercept(...request)) {
          event.preventDefault();

          try {
            const onComplete = new Promise(() => undefined);
            this.chainHandlers(request, onComplete)
              .then((newRequest) => {
                if (newRequest[0] === 'NO_REDIRECT') {
                  return;
                }

                submitFormData(newRequest);
              })
              .catch((error) => {
                void captureError(error);
                submitFormData(request);
              });
          } catch (error) {
            void captureError(error);
            submitFormData(request);
          }
        }
      }
    };

    window.addEventListener('submit', submitListener);
  }
}

function shouldIntercept(input: string | URL, init?: RequestInit) {
  return (
    init?.method?.toLowerCase() === 'post' &&
    shopifyUrlStartsWith(input, 'checkout')
  );
}
