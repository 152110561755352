import { shopifyUrlStartsWith } from './interceptor';
import { RequestInterceptor } from './request-interceptor';

function shouldIntercept(input: string | URL) {
  return shopifyUrlStartsWith(input, 'cart/update');
}

export class CartUpdateInterceptor extends RequestInterceptor {
  constructor() {
    super(shouldIntercept);
  }
}
