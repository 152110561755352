import { CartFormConfig, PurpleDotConfig } from '../purple-dot-config';

// TODO: Add support for other common side cart drawers like Upcart or iCart

const rebuyCartFormConfig: CartFormConfig = {
  propertySelector: '.rebuy-cart__flyout-item-property',
  onNewPropertyKeyToHide: (property, _) => {
    const elementsToHide = property.querySelectorAll<HTMLElement>(
      '.rebuy-cart__flyout-item-property-name, .rebuy-cart__flyout-item-property-separator'
    );

    for (const element of elementsToHide) {
      element.style.display = 'none';
    }
  },
};

export function getCartFormConfig(
  cartForm: HTMLElement,
  config: PurpleDotConfig
): CartFormConfig | undefined {
  if (config?.cartForm) {
    return config?.cartForm;
  }

  if (
    cartForm.classList.contains('rebuy-cart') ||
    cartForm.classList.contains('rebuy-cart__flyout')
  ) {
    return rebuyCartFormConfig;
  }
}
