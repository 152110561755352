const API_TIMEOUT = 10000; // 10 seconds

export async function apiFetch(input: string | URL, options?: RequestInit) {
  const controller = new AbortController();
  setTimeout(() => controller.abort(), API_TIMEOUT);

  return await fetch(input.toString(), {
    signal: options?.signal ?? controller.signal,
    ...options,
  });
}
