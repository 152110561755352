/* eslint-disable no-restricted-syntax */

import { addResourceBundle, t } from '@purple-dot/translations/i18n';

export type CustomisableTranslations = Partial<
  Record<'add-to-cart' | 'pre-order-cta', string>
>;

const validTranslationKeys = [
  'add-to-cart',
  'pre-order-cta',
  'extended-delivery-cta',
  'coming-soon-cta',
  'sold-out',
] as const;

type ValidTranslationKey = (typeof validTranslationKeys)[number];

export function translate(key: ValidTranslationKey, locale: string): string {
  return t(`shopify-app.${key}`, {
    lng: locale,
  });
}

export function customiseTranslations(
  customTranslations: Record<string, Record<string, string>>
) {
  Object.entries(customTranslations).forEach(([locale, translations]) => {
    try {
      validateCustomTranslations(locale, translations);
    } catch (error) {
      // biome-ignore lint/suspicious/noConsole: log
      console.error('Error loading custom translations:', error);
      return;
    }

    addCustomTranslations(locale, translations);
  });
}

function addCustomTranslations(
  locale: string,
  translations: CustomisableTranslations
) {
  addResourceBundle(
    locale,
    'shopify',
    { 'shopify-app': translations },
    true,
    true
  );
}

function validateCustomTranslations(
  locale: string,
  translations: CustomisableTranslations
) {
  Object.keys(translations).forEach((key) => {
    if (!validTranslationKeys.includes(key as ValidTranslationKey)) {
      throw new Error(`Invalid translation key '${key}' for ${locale}`);
    }
  });
}
