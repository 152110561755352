import { setUpCssVariables } from '@purple-dot/design-system/set-up-css-variables';
import { i18nReady } from '@purple-dot/translations/i18n';
import { autoDetectLocale } from '@purple-dot/translations/locale';
import { defineLearnMoreCustomElement } from '@purple-dot/web-components/learn-more/learn-more-web-component';
import { defineSelfServiceCustomElement } from '@purple-dot/web-components/self-service/self-service-web-component';
import { CartTools } from './cart-tools';
import { CheckoutMethod } from './checkout-method';
import { ShopifyThemeDebugListener, configureDebug } from './debug';
import {
  CustomisableTranslations,
  customiseTranslations,
} from './localization';
import { CheckoutMethodEnum, convertConfigIfLegacy } from './purple-dot-config';
import { PurpleDotIntegration } from './purple-dot-integration';
import { setupSDK } from './setup-sdk';
import { ShopifyApi } from './shopify-api';
import { ShopifyTheme } from './shopify-theme';
import { extractHandle } from './shopify-theme/url-utils';
import { WaitlistAvailability } from './waitlist-availability';

const HOST_URL = '__HOST_URL__';
const SCRIPT_VERSION = '__SCRIPT_VERSION__';

// biome-ignore lint/suspicious/noConsole: log
console.info('Running Shopify Script, Version:', SCRIPT_VERSION);

configureDebug();

function initPurpleDot() {
  window.PurpleDotConfig = convertConfigIfLegacy(window.PurpleDotConfig);
  if (!window.PurpleDotConfig.hostURL)
    window.PurpleDotConfig.hostURL = HOST_URL;

  const {
    apiKey,
    checkoutMethod = CheckoutMethodEnum.CombinedCart,
    shopifyCart,
    shopifyCartString,
    shopifyCartSignature,
    discountCode,
    supportedMarkets,
    onUnsupportedMarket,
    enableWaitlists = () => true,
    currency,
    tracking,
    translations,
  } = window.PurpleDotConfig;

  void setUpCssVariables(window.PurpleDotConfig.hostURL);

  if (translations) {
    Object.entries(translations).forEach(([locale, localeTranslations]) => {
      const renamedTranslations: CustomisableTranslations = {};

      if (localeTranslations.addToCart) {
        renamedTranslations['add-to-cart'] = localeTranslations.addToCart;
      }

      if (localeTranslations.preorderCTA) {
        renamedTranslations['pre-order-cta'] = localeTranslations.preorderCTA;
      }

      customiseTranslations({ [locale]: renamedTranslations });
    });
  }

  const locale = autoDetectLocale();

  setupSDK({
    apiKey,
    hostUrl: HOST_URL,
    shopifyCart,
    shopifyCartString,
    shopifyCartSignature,
    discountCode,
    enableCart: checkoutMethod === CheckoutMethodEnum.SeparateCart,
    currency: currency ?? window.Shopify?.currency?.active,
    country: window.Shopify?.country,
    forwardingConfig: tracking,
    prefillEmail: window.PurpleDotConfig.prefillEmail,
    prefillShippingAddress: window.PurpleDotConfig.prefillShippingAddress,
    locale,
  });

  const shopifyApi = new ShopifyApi();
  const waitlistAvailability = new WaitlistAvailability({
    shopifyApi,
    supportedMarkets,
    onUnsupportedMarket,
    enableWaitlists,
  });
  void waitlistAvailability.preload(extractHandle(window.location.href));

  const cartTools = new CartTools(shopifyApi, waitlistAvailability);

  const integration = new PurpleDotIntegration({
    shopifyApi,
    waitlistAvailability,
    config: window.PurpleDotConfig,
    checkoutMethod: CheckoutMethod.buildFromConfig(
      HOST_URL,
      cartTools,
      window.PurpleDotConfig,
      waitlistAvailability
    ),
    locale,
  });

  const shopifyTheme = new ShopifyTheme({
    collectionItemSelector: window.PurpleDotConfig.plp?.collectionItemSelector,
    includeDefaultCollectionItemSelectors:
      window.PurpleDotConfig.plp?.includeDefaultCollectionItemSelectors ??
      window.PurpleDotConfig?.includeDefaultCollectionItemSelectors,
    productPageSelector: window.PurpleDotConfig.pdp?.productPageSelector,
    selectedVariantSelector:
      window.PurpleDotConfig.pdp?.selectedVariantSelector,
    addToCartButtonSelector:
      window.PurpleDotConfig.pdp?.addToCartButtonSelector,
    updateAddToCartButton: window.PurpleDotConfig.pdp?.updateAddToCartButton,
    cartFormSelector: window.PurpleDotConfig.cartFormSelector,
    checkoutButtonSelector: window.PurpleDotConfig.checkoutButtonSelector,
  });

  const themeListeners = integration.getThemeListeners();
  themeListeners.forEach((listener) => {
    shopifyTheme.addListener(listener);
  });

  shopifyTheme.addListener(new ShopifyThemeDebugListener());

  shopifyTheme.listen();

  if (
    !window.PurpleDotConfig?.pdp?.styles &&
    (window.location.href.includes(
      'elite-light-cushion-mini-crew-limited-edition-city-collection'
    ) ||
      window.location.href.includes(
        'elite-light-cushion-no-show-tab-limited-edition-city-collection'
      ))
  ) {
    const qtyStyle = document.createElement('style');
    qtyStyle.innerHTML = `
        form .js-quantity-selector, form .js-qty {
          display: block !important;
        }
        `;
    document.head.appendChild(qtyStyle);
  }
}

void i18nReady.then(() => initPurpleDot());

defineLearnMoreCustomElement();
defineSelfServiceCustomElement();
