import dedent from 'dedent';

export class OverrideStyle {
  private styleSheet: HTMLStyleElement;
  private uniqueClassName: string;

  constructor(private element: HTMLElement) {
    // Generate a unique class to target our stylesheet with.
    // This will work fine as long as the theme doesn't mess with the class list too much.
    // If we hit cases where that happens we'll need to dynamically generate unique CSS selectors
    // for the target element instead.
    this.uniqueClassName = randomClassName();
    this.element.classList.add(this.uniqueClassName);
    this.element.dataset.pdStyleID = this.uniqueClassName;

    this.styleSheet = document.createElement('style');
    this.styleSheet.id = this.uniqueClassName;
    this.styleSheet.classList.add('pd-override-style');
    this.styleSheet.disabled = true;

    element.appendChild(this.styleSheet);
  }

  enable() {
    this.styleSheet.disabled = false;
  }

  disable() {
    this.styleSheet.disabled = true;
  }

  destroy() {
    this.element.dataset.pdStyleID = undefined;
    this.element.classList.remove(this.uniqueClassName);
    this.styleSheet.remove();
  }

  setStyles(css: Record<string, string[]>) {
    const cssText = Object.entries(css)
      .map(
        ([selector, props]) =>
          dedent`
          .${this.uniqueClassName} ${selector} {
            ${props.join(';\n')}
          }`
      )
      .join('\n');

    this.styleSheet.innerHTML = cssText;
  }
}

function randomClassName() {
  return `pd-style-${randomID()}`;
}

function randomID() {
  return Math.random().toString(36).slice(2);
}
