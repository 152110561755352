export function isVisible(elem: Element): boolean {
  return (
    isElementVisible(elem) ||
    (shouldCheckChildrenVisibility(elem) && areSomeChildrenVisible(elem))
  );
}

function shouldCheckChildrenVisibility(elem: Element): boolean {
  // If the parent has opacity: 0, then the children are not visible
  // even if they have the right properties
  return isNotTransparent(elem) && !!elem.children.length;
}

function areSomeChildrenVisible(elem: Element): boolean {
  const { children } = elem;
  return Array.from(children).some(
    (child) =>
      isElementVisible(child) ||
      (shouldCheckChildrenVisibility(child) && areSomeChildrenVisible(child))
  );
}

function isElementVisible(elem: Element): boolean {
  const boundingRect = elem.getBoundingClientRect();
  const computedStyle = window.getComputedStyle(elem);

  return (
    computedStyle.display !== 'none' &&
    computedStyle.visibility !== 'hidden' &&
    isNotTransparent(elem) &&
    boundingRect.height >= 1 &&
    boundingRect.top < window.innerHeight &&
    Math.round(boundingRect.x + boundingRect.width) <= window.innerWidth
  );
}

function isNotTransparent(elem: Element): boolean {
  const computedStyle = window.getComputedStyle(elem);
  return computedStyle.opacity !== '0';
}
