import { PreorderStateListener } from '../purple-dot-integration/preorder-add-to-cart-form';
import { DebugLogger } from './logger';

export class PreorderStateDebugListener
  extends DebugLogger
  implements PreorderStateListener
{
  constructor() {
    super('pre-order-state');
  }

  onMount(...args: any) {
    this.log('onMount', args);
  }

  onPreorder(...args: any) {
    this.log('onPreorder', args);
  }

  inStock(...args: any) {
    this.log('onInStock', args);
  }

  soldOut(...args: any) {
    this.log('onSoldOut', args);
  }

  unknownState(...args: any) {
    this.log('unknownState', args);
  }

  noVariantSelected(...args: any) {
    this.log('noVariantSelected', args);
  }
}
