/**
 * LineItemProperties
 *
 * Controls the hidden input fields that are used
 * inside the Add to Cart Form.
 *
 * e.g
 * <input type="hidden" name="properties[<PROPERTY_NAME>]" value="<PROPERTY_VALUE>" />
 */
class LineItemProperties {
  form!: HTMLElement;

  constructor({ form }: { form: HTMLElement }) {
    this.form = form;
  }

  upsert(name: string, newValue: string) {
    const inputEl = this._findPropertyEl(name);
    if (!inputEl) {
      const newEl = this._createPropertyInput(name, newValue);
      this.form.appendChild(newEl);
    } else {
      inputEl.value = newValue;
    }
  }

  remove(name: string) {
    const el = this._findPropertyEl(name);
    if (el) {
      el.parentElement?.removeChild(el);
    }
  }

  _findPropertyEl(name: string): HTMLInputElement | null {
    const selector = `input[name="properties[${name}]"]`;
    const inputEl = this.form.querySelector<HTMLInputElement>(selector);
    if (!inputEl) {
      return null;
    }

    return inputEl;
  }

  _createPropertyInput(name: string, value: string) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = `properties[${name}]`;
    input.value = value;
    return input;
  }
}

export { LineItemProperties };
