import { createURL } from '@purple-dot/libraries/src/url';

export async function setUpCssVariables(hostURL: string) {
  const url = createURL({
    host: hostURL,
    path: '/api/v1/store-theme',
    queryParams: getStoreIdentifier(),
  });

  let logoColor = '#8F17D1';

  try {
    const res = await fetch(url);
    const theme = await res.json();
    const themeLogoColor = theme?.data?.theme?.pdLogo?.color;

    if (themeLogoColor) {
      logoColor = themeLogoColor;
    }
  } catch (error) {
    // biome-ignore lint/suspicious/noConsole: log
    console.warn('Failed to fetch store theme', error);
  }

  /*
    These styles are global, for app and platform. They're added to both the <head> AND <body>:
    - The head is normal, and loads fast. We want to keep this for most cases
    - The body allows us to override styles that we otherwise couldn't
  */
  const variablesHead = document.createElement('style');
  variablesHead.innerHTML = `
    :root {
      --pd-logo-color: ${logoColor};
    }

    .pd-hide {
      display: none !important;
      height: 0px !important;
      max-height: 0px !important;
      position: absolute !important;
      scale: 0 !important;
      pointer-events: none !important;
      opacity: 0 !important;
      transition: all 0s !important;
    }
  `;
  const variablesBody = variablesHead.cloneNode(true);

  document.head.appendChild(variablesHead);
  document.body.appendChild(variablesBody);
}

// TODO: Dupe from packages/web-components/learn-more/learn-more-modal.ts
function getStoreIdentifier() {
  const apiKey = (window as any).PurpleDotConfig?.apiKey;
  const shop = (window as any).Shopify?.shop;

  if (apiKey) return { api_key: apiKey };

  if (shop) return { shop };

  return {};
}
