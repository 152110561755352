import { InitOptions } from 'i18next';

export function loadAsync(lang: string, ns: string) {
  return import(`./locales/${lang}/${ns}.json`);
}

export function loadSync(lang: string, ns: string) {
  // eslint-disable-next-line @typescript-eslint/no-require-imports
  return require(`./locales/${lang}/${ns}.json`);
}

export const baseConfig: InitOptions = {
  lng: 'en',
  fallbackLng: {
    'es-419': ['es-MX', 'es'],
    default: ['en'],
  },
  supportedLngs: ['en', 'en-GB', 'es', 'es-419', 'es-MX', 'fr', 'de', 'it'],
  ns: ['translation', 'email', 'countries', 'shopify'],
  defaultNS: 'translation',
};
