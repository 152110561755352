import { CartTools } from '../cart-tools';
import { CheckoutMethodEnum, PurpleDotConfig } from '../purple-dot-config';
import { WaitlistAvailability } from '../waitlist-availability';
import { CombinedBagCheckoutMethod } from './combined-checkout-method';
import { ExpressCheckoutMethod } from './express-checkout-method';
import { SeparateBagCheckoutMethod } from './separate-bag-checkout-method';

class CheckoutMethod {
  static buildFromConfig(
    hostUrl: string,
    cartTools: CartTools,
    config: PurpleDotConfig,
    waitlistAvailability: WaitlistAvailability
  ) {
    if (config.checkoutMethod === CheckoutMethodEnum.Express) {
      return new ExpressCheckoutMethod({
        hostUrl,
        integrationPoints: config,
        enableNotifyMe: config.enableNotifyMe,
        waitlistAvailability,
      });
    }
    if (config.checkoutMethod === CheckoutMethodEnum.SeparateCart) {
      return new SeparateBagCheckoutMethod({
        hostUrl,
        integrationPoints: config,
        enableNotifyMe: config.enableNotifyMe,
        waitlistAvailability,
      });
    }
    return new CombinedBagCheckoutMethod({
      cartTools,
      integrationPoints: config,
      waitlistAvailability,
    });
  }
}

export { CheckoutMethod };
