import { GetWaitlistsResponseItem } from '@purple-dot/main/src/presentation-layer/custom-server/api/public/views/get-waitlists-response';
import { ShopifyProduct } from '../shopify-api';
import { AddToCartForm } from '../shopify-theme/add-to-cart-form';
import {
  PreorderState,
  VariantState,
  WaitlistAvailability,
} from '../waitlist-availability';
import {
  NewEndpointPreorderState,
  fetchProductsPreorderState,
  fetchVariantsPreorderState,
} from './backend';

/**
 * Listens for Add To Cart Forms
 *
 * and raises events based on what the
 * state of the form is
 */

export interface PreorderStateListener {
  onMount: (addToCartForm: AddToCartForm) => void;
  onPreorder: (
    product: ShopifyProduct,
    waitlist: GetWaitlistsResponseItem,
    variantId: number,
    addToCartForm: AddToCartForm
  ) => void;
  inStock: (
    product: ShopifyProduct,
    waitlist: GetWaitlistsResponseItem | null,
    variantId: number,
    addToCartForm: AddToCartForm
  ) => void;
  soldOut: (
    product: ShopifyProduct,
    waitlist: GetWaitlistsResponseItem | null,
    variantId: number,
    addToCartForm: AddToCartForm
  ) => void;
  unknownState: (variantId: number, addToCartForm: AddToCartForm) => void;
  noVariantSelected: (
    product: ShopifyProduct,
    waitlist: GetWaitlistsResponseItem | null,
    addToCartForm: AddToCartForm,
    state: NewEndpointPreorderState | undefined
  ) => Promise<void> | void;
}

class PreorderAddToCartForm {
  waitlistAvailability!: WaitlistAvailability;
  atcForm: AddToCartForm;
  listeners: PreorderStateListener[];

  constructor({
    waitlistAvailability,
    atcForm,
  }: {
    waitlistAvailability: WaitlistAvailability;
    atcForm: AddToCartForm;
  }) {
    this.waitlistAvailability = waitlistAvailability;
    this.atcForm = atcForm;
    this.listeners = [];
  }

  async mount() {
    for (const listener of this.listeners) {
      listener.onMount(this.atcForm);
    }

    this.atcForm.onVariantIdChange(async (newVariantId) => {
      await this._onVariantIdChange(newVariantId);
    });

    const variantId = this.atcForm.getVariantId();
    await this._onVariantIdChange(variantId);
  }

  addListener(listener: PreorderStateListener) {
    this.listeners.push(listener);
  }

  async _onVariantIdChange(variantId?: number | null) {
    if (variantId) {
      await this._onVariantSelectedNewEndpoint(this.atcForm, variantId);
    } else {
      await this._onNoVariantSelected(this.atcForm);
    }
  }

  async _onVariantSelectedNewEndpoint(
    addToCartForm: AddToCartForm,
    variantId: number
  ) {
    const response = await fetchVariantsPreorderState(variantId);
    if (!response) {
      for (const listener of this.listeners) {
        listener.unknownState(variantId, addToCartForm);
      }
      return;
    }

    const product = await this.waitlistAvailability.getProduct({
      handle: response?.product.handle,
    } as any);
    if (!product) {
      for (const listener of this.listeners) {
        listener.unknownState(variantId, addToCartForm);
      }
      return;
    }

    let { state } = response;
    if (state === NewEndpointPreorderState.NoOpenWaitlists) {
      // MEGAHACK: Lisa Says Gah reuse add to cart forms when switching products
      // on the PDP. This means that the variantId changes to a different
      // product's variant but the page doesn't reload. We correctly pick up the
      // product being switched to as having no open waitlists and therefore
      // don't update anything. Except if the previous product was on pre-order,
      // we do need to update things. So we call the old endpoint to find out if
      // the new variant is in stock or sold out, and fake the state. Gah!
      if (isLisaSaysGah()) {
        const { available_in_stock, oversell_enabled } =
          (await this.waitlistAvailability.getVariantInventory(variantId)) ??
          {};

        state =
          (available_in_stock && available_in_stock > 0) || oversell_enabled
            ? NewEndpointPreorderState.AvailableInStock
            : NewEndpointPreorderState.SoldOut;
      } else {
        return;
      }
    }

    const waitlist = response?.waitlist
      ? {
          ...response.waitlist,
          state: 'OPEN',
          product: {
            id: response.product.id,
            product_code: response.product.handle,
          },
        }
      : null;

    if (state === NewEndpointPreorderState.OnPreorder) {
      /**
       * If waitlists are not enabled, this means that we are not selling pre-orders
       * for that particular item.
       *
       * e.g the merchant doesnt' want to sell pre-orders in a certain country
       * In which case we need to set to Sold Out as continue selling could be enabled
       */
      const waitlistsEnabled =
        await this.waitlistAvailability.waitlistsEnabled();
      if (waitlistsEnabled && waitlist) {
        for (const listener of this.listeners) {
          listener.onPreorder(product, waitlist, variantId, addToCartForm);
        }
      } else {
        for (const listener of this.listeners) {
          listener.soldOut(product, waitlist, variantId, addToCartForm);
        }
      }
    } else if (state === NewEndpointPreorderState.AvailableInStock) {
      for (const listener of this.listeners) {
        listener.inStock(product, waitlist, variantId, addToCartForm);
      }
    } else if (state === NewEndpointPreorderState.SoldOut) {
      for (const listener of this.listeners) {
        listener.soldOut(product, waitlist, variantId, addToCartForm);
      }
    } else {
      throw new TypeError(`Unexpected variant state: ${state}`);
    }
  }

  async _onNoVariantSelected(atcForm: AddToCartForm) {
    const handle = atcForm.getHandle();

    if (handle) {
      const [response, waitlistsEnabled, product] = await Promise.all([
        fetchProductsPreorderState(handle),
        this.waitlistAvailability.waitlistsEnabled(),
        this.waitlistAvailability.getProduct({
          handle,
        } as any),
      ]);

      const waitlist = response?.waitlist
        ? {
            ...response.waitlist,
            state: 'OPEN',
            product: {
              id: product?.id.toString(),
              product_code: product?.handle,
            },
          }
        : null;

      if (product) {
        for (const listener of this.listeners) {
          await listener.noVariantSelected(
            product,
            waitlist,
            this.atcForm,
            waitlistsEnabled
              ? response?.state
              : NewEndpointPreorderState.SoldOut
          );
        }
      }
    }
  }

  _onVariantSelected(
    addToCartForm: AddToCartForm,
    entry: VariantState,
    variantId: number
  ) {
    const { state, waitlist, product } = entry;

    if (!product) {
      throw new Error('Product not found');
    }

    if (state === PreorderState.Preorder) {
      const w = waitlist as GetWaitlistsResponseItem;
      for (const listener of this.listeners) {
        listener.onPreorder(product, w, variantId, addToCartForm);
      }
    } else if (state === PreorderState.Available) {
      for (const listener of this.listeners) {
        listener.inStock(product, waitlist, variantId, addToCartForm);
      }
    } else if (state === PreorderState.SoldOut) {
      for (const listener of this.listeners) {
        listener.soldOut(product, waitlist, variantId, addToCartForm);
      }
    } else if (state === PreorderState.Unknown) {
      for (const listener of this.listeners) {
        listener.unknownState(variantId, addToCartForm);
      }
    } else {
      throw new Error(state);
    }
  }
}

function isLisaSaysGah() {
  return window.Shopify?.shop === 'lisasays-gah.myshopify.com';
}

export { PreorderAddToCartForm };
